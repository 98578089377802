import React, { useState } from "react";
import "./list.css"; // Új, elszigetelt CSS fájl
import { getSlimmersIntern } from "../../../Services/Api/AddSlimmerApi";

const SlimmerList = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [slimmers, setSlimmers] = useState([]);
  const [error, setError] = useState("");
  const [usernameCounts, setUsernameCounts] = useState({}); // Új állapot a statisztikához

  const handleFetchData = async () => {
    if (!startDate || !endDate) {
      setError("Kérlek, adj meg egy kezdő és egy végdátumot.");
      return;
    }

    try {
      const response = await getSlimmersIntern(startDate, endDate);
      setSlimmers(response);
      setError("");

      // Felhasználónkénti bejegyzések összesítése
      const counts = {};
      response.forEach((entry) => {
        counts[entry.username] = (counts[entry.username] || 0) + 1;
      });
      setUsernameCounts(counts);
      
    } catch (err) {
      console.error("Hiba történt az adatok lekérése során:", err);
      setError("Hiba történt az adatok lekérése során.");
    }
  };

  return (
    <div className="slimmer-container">
      <h4 className="slimmer-title">Slimmer adatok dátum szerint</h4>

      <div className="slimmer-card">
        <h6 className="slimmer-subtitle">Keresési feltételek</h6>

        <div className="slimmer-input-group">
          <label>Kezdő dátum</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="slimmer-input"
          />
        </div>

        <div className="slimmer-input-group">
          <label>Vég dátum</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="slimmer-input"
          />
        </div>

        <button onClick={handleFetchData} className="slimmer-btn">Lekérdezés</button>
      </div>

      {error && <p className="slimmer-error">{error}</p>}

      {/* Felhasználónkénti statisztika */}
      {Object.keys(usernameCounts).length > 0 && (
        <div className="slimmer-card">
          <h6 className="slimmer-subtitle">Felhasználónkénti bejegyzések</h6>
          <ul className="slimmer-user-list">
            {Object.entries(usernameCounts).map(([username, count]) => (
              <li key={username} className="slimmer-user-item">
                <strong>{username}</strong>: {count} bejegyzés
              </li>
            ))}
          </ul>
        </div>
      )}

      {slimmers.length > 0 && (
        <div className="slimmer-card">
          <h6 className="slimmer-subtitle">Találatok</h6>
          <div className="slimmer-table-container">
            <table className="slimmer-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Mentés ideje</th>
                </tr>
              </thead>
              <tbody>
                {slimmers.map((slimmer) => (
                  <tr key={slimmer.id}>
                    <td>{slimmer.id}</td>
                    <td>{slimmer.username}</td>
                    <td>{new Date(slimmer.saved_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlimmerList;