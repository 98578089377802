import React, { useState, useEffect } from "react";
import {
  fetchInventoryByLocation,
  updateInventory,
} from "../../Services/Api/InventoryApi";
import jwt_decode from "jwt-decode";
import "./Raktarkeszlet.css"; // Külső CSS fájl a stílusokhoz

export default function Raktarkeszlet() {
  const [selectedLocation, setSelectedLocation] = useState("9");
  const [inventory, setInventory] = useState([]);
  const [updates, setUpdates] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [isInventoryManager, setIsInventoryManager] = useState(false);
  useEffect(() => {
    const loadInventory = async () => {
      if (selectedLocation) {
        const inventoryData = await fetchInventoryByLocation(selectedLocation);
        setInventory(inventoryData);
      }
    };
    loadInventory();
  }, [selectedLocation]);

  useEffect(() => {
        try {
          let decodedUser = jwt_decode(localStorage.getItem("accessToken"));
          setIsInventoryManager(decodedUser["jobrole"] == "inventory")
        } catch (error) {
          localStorage.clear();
          window.location = '/login';
          console.error("Invalid token specified", error);
          localStorage.setItem('isUserLoggedIn', false);
        }
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleInventoryChange = (itemId, subtype, value) => {
    setUpdates((prev) => ({
      ...prev,
      [`${itemId}-${subtype}`]: parseInt(value),
    }));
  };

  const handleSubmitInventory = async () => {
    if(isInventoryManager){
      alert("Nem engedélyezett művelet!");
      return;
    }
    await updateInventory(selectedLocation, updates);
    const inventoryData = await fetchInventoryByLocation(selectedLocation);
        setInventory(inventoryData);
        setUpdates({})
    alert("Raktárkészlet frissítve");
  };

  return (
    <div className="containerok">
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "stretch" : "center",
          gap: "10px"
        }}
      >
        <label style={{ fontWeight: "bold" }}>Lokáció kiválasztása:</label>
        <select
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          style={{
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            width: isMobile ? "100%" : "auto"
          }}
        >
          <option value="1">Westend</option>
          <option value="9">Iroda</option>
        </select>
      </div>
      <h1>Raktárkészlet kezelés</h1>

      {inventory.length > 0 && (
        <div className="inventory-table-container">
          <table className="inventory-table">
            <thead>
              <tr>
                <th>Termék</th>
                <th>Típus</th>
                <th>Jelenlegi készlet</th>
                <th>Új érték</th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((item) => {
                return (
                  <tr key={`${item.item_id}-${item.phonetype}`}>
                    <td>{item.item_name}</td>
                    <td>{item.phonetype !== "-" ? item.phonetype : ""}</td>
                    <td>{item.quantity} db</td>
                    <td>
                      <input
                        type="number"
                        value={
                          updates[`${item.item_id}-${item.phonetype}`] ?? item.quantity
                        }
                        disabled={isInventoryManager}
                        onChange={(e) =>
                          handleInventoryChange(
                            item.item_id,
                            item.phonetype,
                            e.target.value
                          )
                        }
                        className="input-field"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

        <button onClick={handleSubmitInventory} className="submit-button">
          Készlet frissítése
        </button>
    </div>
  );
}
