import React, { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import axios from 'axios';
import '../Attende.css';
import { postSlimmerIntern, getSlimmersIntern } from "../../../Services/Api/AddSlimmerApi";

const SlimmerIntern = () => {
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleRegister = async (email) => {
    try {
      const response = await postSlimmerIntern(email);
      setResponseMessage(response.message);
    } catch (error) {
      console.error('Hiba történt:', error.message);
      setResponseMessage('Valami hiba történt a regisztráció során.');
    }
    setIsScannerOpen(false);
  };

  const handleScan = (result) => {
    if (result && result[0] && result[0].rawValue) {
      try {
        const decodedData = atob(result[0].rawValue);
        const jsonData = JSON.parse(decodedData);
        const email = jsonData.Email;
        handleRegister(email);
      } catch (error) {
        console.error('Hiba a QR kód feldolgozása során:', error);
        window.alert("Hibás QR kód formátum");
      }
    }
  };

  const handleError = (error) => {
    console.error('QR kód hiba:', error);
    window.alert("Hibás QR kód");
  };

  const handleOpenScanner = () => {
    setIsScannerOpen(true);
  };

  const handleCloseModal = () => {
    setResponseMessage('');
    setIsScannerOpen(false);
  };

  return (
    <div className="container2">
      <h1>Slimmer lecsipogása regisztráció után</h1>
      <button className="register-button" onClick={handleOpenScanner}>
        Lecsipogás
      </button>

      {isScannerOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>QR kód beolvasása</h2>
            <Scanner
              onScan={handleScan}
              onError={handleError}
              constraints={{ facingMode: 'environment' }}
              style={{ width: '100%', height: '300px' }} // Mobilon jobb méretarány
            />
          </div>
        </div>
      )}

      {responseMessage && (
        <div className="response-popup">
          <p>{responseMessage}</p>
          <button onClick={handleCloseModal}>Ok</button>
        </div>
      )}
    </div>
  );
};

export default SlimmerIntern;