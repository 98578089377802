import React, {useContext, useState, useEffect} from 'react';
import './Statisztika.css';
import { format, isAfter } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faMoneyBill1Wave, faHandSparkles, faMobileScreen, faBattery, faHammer, faTabletScreenButton, faPersonDress, faIdCard } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import Popup from 'reactjs-popup';
import "react-datepicker/dist/react-datepicker.css";
import { ThemeContext } from '../App/ThemeContext';
import { hu } from 'date-fns/locale';
import 'reactjs-popup/dist/index.css';
import { getStatisticsByDate } from '../../Services/Api/StatApi';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Bar, Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

export default function Statisztika() {
  const { theme } = useContext(ThemeContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const primaryColor = theme.primary;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filter, setFilter] = useState("Összes");
  const [isNewSystem, setIsNewSystem] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  
  const formatResult = (result) => {
    // Ha az eredmény .00-ra végződik, akkor 0 tizedes jegy, különben 2 tizedesjegy
    if(isNewSystem) {
      return result.toFixed(0);
    } else {
      return result % 1 === 0 ? result.toFixed(0) : result.toFixed(1);
    }
  };

  useEffect(() => {
    
    fetchStat(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    const interval = setInterval(() => {
      fetchStat(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    }, 150000);
    return () => clearInterval(interval);
  }, [startDate, endDate, filter]);
  
  const setDataAndFetch = (date) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
    fetchStat(format(date, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
  };
  
  const setendDateAndFetch = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setStartDate(date);
    }
    fetchStat(format(startDate, "yyyy-MM-dd"), format(date, "yyyy-MM-dd"));
  };

        const fetchStat = (time, endTime) => {
          setIsLoading(true);
          if (isAfter(startDate, new Date("2025-03-01"))) {
            setIsNewSystem(true);
          } else {
            setIsNewSystem(false);
          }
          if (time && endTime) {
            getStatisticsByDate(time, endTime, filter).then((response) => {
              const productTotals = response.product_totals || {
                total_income: 0,
                total_slimmer: 0,
                bank_total: 0,
                bank_total_count: 0,
                cash_total: 0,
                cash_total_count: 0,
                total_purchases: 0,
                total_computed: 0,
                item_sales: {},
              };
              const locationTotals = response.location_totals || {};
              const userTotals = response.user_totals || {};
              const locationPieData = {
                labels: Object.keys(locationTotals),
                datasets: [
                  {
                    data: Object.values(locationTotals).map(
                      (loc) => loc.total_purchases || 0
                    ),
                    backgroundColor: [
                      "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
                      "#9966FF", "#FF9F40", "#E57373", "#64B5F6"
                    ],
                    hoverBackgroundColor: [
                      "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
                      "#9966FF", "#FF9F40", "#E57373", "#64B5F6"
                    ],
                  },
                ],
              };
      
              // Bar Chart Data (Lokációk bevételei)
              const locationBarData = {
                labels: Object.keys(locationTotals),
                datasets: [
                  {
                    label: "Bevétel (Ft)",
                    data: Object.values(locationTotals).map(
                      (loc) => loc.total_income || 0
                    ),
                    backgroundColor: [
                      "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
                      "#9966FF", "#FF9F40", "#E57373", "#64B5F6",
                      "#81C784", "#FFD54F", "#4DD0E1", "#BA68C8"
                    ],
                  },
                ],
              };
      
              // Doughnut Chart Data (Fizetési típusok lokációnként)
              const locationDoughnutData = {
                labels: ["Bankkártyás", "Készpénzes"],
                datasets: Object.keys(locationTotals).map((location) => ({
                  label: location,
                  data: [
                    locationTotals[location]?.bank_total || 0,
                    locationTotals[location]?.cash_total || 0,
                  ],
                  backgroundColor: ["#36A2EB", "#FFCE56"],
                })),
              };
              // Pie Chart Data
              const pieData = {
                labels: Object.keys(productTotals.item_sales || {}),
                datasets: [
                  {
                    data: Object.values(productTotals.item_sales || {}).map(
                      (item) => item.total
                    ),
                    backgroundColor: [
                      "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
                      "#9966FF", "#FF9F40", "#E57373", "#64B5F6",
                      "#81C784", "#FFD54F", "#4DD0E1", "#BA68C8"
                    ],
                    hoverBackgroundColor: [
                      "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
                      "#9966FF", "#FF9F40", "#E57373", "#64B5F6",
                      "#81C784", "#FFD54F", "#4DD0E1", "#BA68C8"
                    ],
                  },
                ],
              };
        
              // Bar Chart Data
              const barData = {
                labels: ["Bankkártyás", "Készpénzes"],
                datasets: [
                  {
                    label: "Összeg",
                    data: [
                      productTotals.bank_total || 0,
                      productTotals.cash_total || 0,
                    ],
                    backgroundColor: ["#4BC0C0", "#FF6384"],
                  },
                ],
              };
              const FtOptions = {
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const value = context.raw || 0;
                        return `${value.toLocaleString()} Ft`;
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: function (value) {
                        return `${value.toLocaleString()} Ft`; 
                      },
                    },
                  },
                },
              };
              const DbOptions = {
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const label = context.label || "";
                        const value = context.raw || 0;
                        return ` ${value} db`;
                      },
                    },
                  },
                },
              };
              const DoughnutFtOptions = {
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        console.log(context)
                        const label = context.label || "";
                        const value = context.raw || 0;
                        return `${context["dataset"]["label"]}: ${value.toLocaleString()} Ft`;
                      },
                    },
                  },
                },
              };
        const doughnutData = {
          labels: ["Bankkártyás", "Készpénzes"],
          datasets: [
            {
              data: [productTotals.bank_total_count, productTotals.cash_total_count],
              backgroundColor: ["#36A2EB", "#FFCE56"],
              hoverBackgroundColor: ["#36A2EB", "#FFCE56"],
            },
          ],
        };
              // Adatok beállítása
              setData({
                product_totals: productTotals,
                user_totals: userTotals,
                charts: {
                  pieData,
                  barData,
                  doughnutData,
                  locationPieData,
                  locationBarData,
                  locationDoughnutData,
                  FtOptions,
                  DbOptions,
                  DoughnutFtOptions
                },
              });
        
              setIsLoading(false);
            }).catch((error) => {
              console.error("Hiba történt a fetchStat során:", error);
              setIsLoading(false);
            });
          }
        };

  return(
    <div id='statisztika'>
      <h2 style={{ color: primaryColor}}>Statisztika</h2>
      <p className="inputLabel">Kezdődátum</p>
        <DatePicker
          classname="datepicker"
          selected={startDate}
          onChange={(date) => setDataAndFetch(date)}
          locale={hu}
        />
        <p className="inputLabel">Végedátum</p>
        <DatePicker
          classname="datepicker"
          selected={endDate}
          onChange={(date) => setendDateAndFetch(date)}
          locale={hu}
        /><br></br>
<div>
            <p className="inputLabel">Helyszín</p>
            <select
              className="mobileInput"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="Összes">Összes</option>
              <option value="Westend">Westend</option>
              <option value="Westend II">Westend II</option>
              <option value="Westend III">Westend III</option>
              <option value="Westend bolt">Westend bolt</option>
              <option value="Andrássy üzlet">Andrássy üzlet</option>
              <option value="Pólus Center">Pólus Center</option>
              <option value="Campona">Campona</option>
              <option value="Webshop">Webshop</option>
            </select>
          </div>
          <br></br>
        {isLoading && <div className="loader" style={{ borderTopColor: primaryColor }} />}
        {!isLoading && data && data.product_totals && Object.keys(data.user_totals).length > 0 ? (
<div>
<button
        onClick={handleOpenPopup}
        style={{
          border: "2px solid",
          borderColor: primaryColor,
          color: primaryColor,
          backgroundColor: "white",
          borderRadius: "10px",
          fontSize: "16px",
          padding: "5px",
          marginBottom: "10px",
        }}
      >
        Grafikonok megtekintése
      </button><br></br>
      <a
        href="#tovabbi-stat"
        style={{
          border: "2px solid",
          borderColor: primaryColor,
          color: primaryColor,
          backgroundColor: "white",
          borderRadius: "10px",
          fontSize: "13px",
          marginTop: "20px",
          textDecoration: "none",
          padding: "2px",
        }}
      >
        További statisztikák
      </a>
      <div className='productAll'>


      <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
  <div className="chartPopup">
    <h2 style={{ color: primaryColor }}>Statisztikai Chartok</h2>

    {/* Pie Chart */}
    <div style={{ marginBottom: "20px" }}>
      <h3>Összesített Vásárlások Típusonként</h3>
      <Pie label="db" data={data.charts.pieData}  options={data.charts.DbOptions}/>
    </div>

    {/* Bar Chart */}
    <div style={{ marginBottom: "20px" }}>
      <h3>Bankkártyás vs. Készpénzes bevétel</h3>
      <Bar data={data.charts.barData} options={data.charts.FtOptions} />
    </div>

    {/* Doughnut Chart */}
    <div style={{ marginBottom: "20px" }}>
      <h3>Bankkártyás vs. Készpénzes darab</h3>
      <Pie data={data.charts.doughnutData} options={data.charts.DbOptions} />
    </div>

    <h2 style={{ color: primaryColor }}>Lokációk Statisztikái</h2>

    {/* Pie Chart */}
    <div style={{ marginBottom: "20px" }}>
      <h3>Vásárlások Megoszlása Lokációnként</h3>
      <Pie data={data.charts.locationPieData} options={data.charts.DbOptions}/>
    </div>

    {/* Bar Chart */}
    <div style={{ marginBottom: "20px" }}>
      <h3>Bevétel Lokációnként</h3>
      <Bar data={data.charts.locationBarData} options={data.charts.FtOptions} />
    </div>

    {/* Doughnut Chart */}
    <div style={{ marginBottom: "20px" }}>
      <h3>Fizetési Típusok Lokációnként</h3>
      <Doughnut data={data.charts.locationDoughnutData} options={data.charts.DoughnutFtOptions}/>
    </div>
  </div>
</Popup>
        <div className='productRow' style={{ borderColor: primaryColor }}>
          <p style={{ color: primaryColor, fontSize: "12px" }}>Összesítő</p>
          <h3 style={{ margin: "5px" }}>{formatResult(data.product_totals.total_computed)} {isNewSystem ? "pont" : "db"}</h3>
          <p className='financialDiv'>{data.product_totals.total_income.toLocaleString()} Ft</p>
          <br />
          <p className='productType' style={{ backgroundColor: primaryColor }}>
          {data.product_totals.total_purchases} vásárló - {data.product_totals.total_slimmer} Slimmer
          </p>
      </div>
      {!isLoading && data && data.user_totals && Object.keys(data.user_totals).length > 0 ? (
  Object.entries(data.user_totals)
    .filter(([userName]) => userName !== "") // Az üres kulcs kiszűrése
    .map(([userName, userStats]) => {
      return (
              <div className='statUserRow' style={{ borderColor: primaryColor}}>
                
            <div className='paymentDiv'>
            <p style={{ color: "white", backgroundColor: primaryColor}} className='paymentQty resultlabel'>{formatResult(userStats.computed_units)} {isNewSystem ? "pont" : "db"}</p>
            <p className='productType pay' style={{ color: primaryColor, background: "white", fontWeight: "800", fontSize: "15px"}}>{userName}</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faPersonDress} />
            <p className='financialDiv' style={{backgroundColor: primaryColor, color: "white", border: "none"}}>{userStats.total_purchases} vásárló</p>
            <p className='financialDiv' style={{backgroundColor: primaryColor, color: "white", border: "none"}}>{userStats.slimmer_purchases} Slimmer</p>
            
                  <p className='financialDiv'>{userStats.total_income.toLocaleString()} Ft</p>
                    
                  <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>
                  <div className="nagyDiv">
                  <h2 style={{ color: "white", textAlign: "center", backgroundColor: primaryColor, padding: "5px", borderRadius: "10px" }}>Termékek Részletezése</h2>
    {userStats.product_sales &&
      Object.entries(userStats.product_sales).map(
        ([productName, productData]) => (
          <div
            key={productName}
            style={{
              borderBottom: "1px dashed",
              borderColor: primaryColor,
              paddingBottom: "10px"
            }}
          >
            <h3 style={{ color: primaryColor, marginBottom: "2px" }}>{productName}</h3>
            <p className='productType pay'>
              <strong>Összesen:</strong> {productData.total} db
            </p>
            {productData.mobile_types &&
              Object.entries(productData.mobile_types).map(
                ([mobileType, count]) => (
                  <div
                    key={mobileType}
                    style={{fontSize: "14px", paddingBottom: "10px" }}
                  >
                    <p>
                      <strong>{mobileType}:</strong> {count} db
                    </p>
                  </div>
                )
              )}
          </div>
        )
      )}
  </div>
                    <div className='nagyDiv'>
                    <h2 style={{ color: "white", textAlign: "center", backgroundColor: primaryColor, padding: "5px", borderRadius: "10px" }}>Selejt</h2>
                    {userStats.scraps &&
                      Object.entries(userStats.scraps).map(
                        ([productName, productData]) => (
                          <div
                              key={productName}
                              style={{
                                borderBottom: "1px dashed",
                                borderColor: primaryColor,
                                paddingBottom: "10px"
                              }}
                            >
                            <h3 style={{ color: primaryColor, marginBottom: "2px" }}>{productName}</h3>
                            <p className='productType pay'>
                              <strong>Összesen:</strong> {productData} db
                            </p>
                    </div>
                    )
                  )}
                      </div>
                    
                    <div className='nagyDiv'>
                    <h2 style={{ color: "white", textAlign: "center", backgroundColor: primaryColor, padding: "5px", borderRadius: "10px" }}>Csere</h2>
                    {userStats.replaces &&
                      Object.entries(userStats.replaces).map(
                        ([productName, productData]) => (
                          <div
                            key={productName}
                            style={{
                              borderBottom: "1px dashed",
                              borderColor: primaryColor,
                              paddingBottom: "10px"
                            }}
                          >
                            <h3 style={{ color: primaryColor, marginBottom: "2px" }}>{productName}</h3>
                            <p className='productType pay'>
                              <strong>Összesen:</strong> {productData} db
                            </p>
                    </div>
                    )
                  )}
                      </div>
                  </Popup> 
                      </div>
                <div> 
                  
                <div className='paymentDiv paymentDiv1'>
            <p className='paymentQty'>{userStats.bank_total_count} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Bankkártyás</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faCreditCard} />
            <p className='financialDiv'>{userStats.bank_total.toLocaleString()} Ft</p>
                </div>
                <div className='paymentDiv paymentDiv1'>
            <p className='paymentQty '>{userStats.cash_total_count} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Készpénzes</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMoneyBill1Wave} />
            <p className='financialDiv'>{userStats.cash_total.toLocaleString()} Ft</p>
                  </div>
                  </div>
                  
                </div>
              
          )
})) : (
  <div></div>
)}
      <div id='tovabbi-stat' className='statRow' style={{ borderColor: primaryColor}}>
          <div className='paymentDiv'>
            <p className='paymentQty' >{data.product_totals.bank_total_count} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Bankkártyás</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faCreditCard} /><br></br>
            <p className='financialDiv'>{data.product_totals.bank_total.toLocaleString()} Ft</p>
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.cash_total_count} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Készpénzes</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMoneyBill1Wave} />
            <p className='financialDiv'>{data.product_totals.cash_total.toLocaleString()} Ft</p>
          </div>
        </div>
        <div className='statRow' style={{ borderColor: primaryColor}}>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.item_sales["Slim HD"] ? data.product_totals.item_sales["Slim HD"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.item_sales["Slim Self-healing"] ? data.product_totals.item_sales["Slim Self-healing"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Self-healing</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.item_sales["Slim Privacy Matt"] ? data.product_totals.item_sales["Slim Privacy Matt"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.item_sales["Slim Privacy HD"] ? data.product_totals.item_sales["Slim Privacy HD"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.item_sales["Slim UV"] ? data.product_totals.item_sales["Slim UV"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim UV</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.item_sales["Slim Matt"] ? data.product_totals.item_sales["Slim Matt"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
        </div>

        <div className='statRow' style={{ borderColor: primaryColor}}>
        <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.item_sales["Tok"] ? data.product_totals.item_sales["Tok"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Tok</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHammer} />
            <br></br>
            <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>
            {data.product_totals.item_sales &&
        Object.entries(data.product_totals.item_sales)
        .filter(([itemType]) => itemType === "Tok")
        .map(([itemType, itemData]) => (
          <div class="centerDiv" key={itemType}>
            <h2 class="statTitle">{itemType} ({itemData.total} db)</h2>
      
            {itemData.mobile_types && Object.keys(itemData.mobile_types).length > 0 ? (
              <div >
                {Object.entries(itemData.mobile_types).map(([mobileType, count]) => (
                  <div>
                  <p class="productType pay">{count} db - {mobileType} </p>
                  </div>
                ))}
              </div>
            ) : (
              <p>Nincs elérhető mobil típus.</p>
            )}
          </div>
        ))}
            </Popup>
          </div>
          <div className='paymentDiv' >
            <p className='paymentQty'>{data.product_totals.item_sales["iPad fóliák"] ? data.product_totals.item_sales["iPad fóliák"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>iPad fóliák</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
            <br></br>
            <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>
            {data.product_totals.item_sales &&
        Object.entries(data.product_totals.item_sales)
        .filter(([itemType]) => itemType === "iPad fóliák")
        .map(([itemType, itemData]) => (
          <div class="centerDiv" key={itemType}>
            <h2 class="statTitle">{itemType} ({itemData.total} db)</h2>
      
            {itemData.mobile_types && Object.keys(itemData.mobile_types).length > 0 ? (
              <div >
                {Object.entries(itemData.mobile_types).map(([mobileType, count]) => (
                  <div>
                  <p class="productType pay">{count} db - {mobileType} </p>
                  </div>
                ))}
              </div>
            ) : (
              <p>Nincs elérhető mobil típus.</p>
            )}
          </div>
        ))}
            </Popup>
          </div>
          <div className='paymentDiv' >
            <p className='paymentQty'>{data.product_totals.item_sales["Slim Power"] ? data.product_totals.item_sales["Slim Power"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Power</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faBattery} />
          </div>
          <div className='paymentDiv' >
            <p className='paymentQty'>{data.product_totals.item_sales["Slimmer (usb lightning) kábel"] ? data.product_totals.item_sales["Slimmer (usb lightning) kábel"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slimmer (usb-lightning) kábel</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faBattery} />
          </div>
          <div className='paymentDiv' >
            <p className='paymentQty'>{data.product_totals.item_sales["Slimmer (usb usb) kábel"] ? data.product_totals.item_sales["Slimmer (usb usb) kábel"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slimmer (usb-usb) kábel</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faBattery} />
          </div>
          <div className='paymentDiv' >
            <p className='paymentQty'>{data.product_totals.item_sales["Slimmer adapter"] ? data.product_totals.item_sales["Slimmer adapter"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slimmer Adapter</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faBattery} />
          </div>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.item_sales["Kártya"] ? data.product_totals.item_sales["Kártya"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Kártyák</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faIdCard} />
            <br></br>
            <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>
            {data.product_totals.item_sales &&
        Object.entries(data.product_totals.item_sales)
        .filter(([itemType]) => itemType === "Kártya")
        .map(([itemType, itemData]) => (
          <div class="centerDiv" key={itemType}>
            <h2 class="statTitle">{itemType} ({itemData.total} db)</h2>
      
            {itemData.mobile_types && Object.keys(itemData.mobile_types).length > 0 ? (
              <div>
                {Object.entries(itemData.mobile_types).map(([mobileType, count]) => (
                  <div>
                  <p class="productType pay">{count} db - {mobileType} </p>
                  </div>
                ))}
              </div>
            ) : (
              <p>Nincs elérhető mobil típus.</p>
            )}
          </div>
        ))}
            </Popup>
          </div>
        </div>
        <div className='statRow' style={{ borderColor: primaryColor, display: "block"}}>

        <div style={{fontSize: "13px", backgroundColor: primaryColor, color: "white", padding: "5px", borderRadius: "15px"}}>Selejt</div>
        <div style={{display: "inline-flex"}}>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.scraps["Slim HD"] ? data.product_totals.scraps["Slim HD"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.scraps["Slim Self-healing"] ? data.product_totals.scraps["Slim Self-healing"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Self-healing</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.scraps["Slim Privacy Matt"] ? data.product_totals.scraps["Slim Privacy Matt"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.scraps["Slim Privacy HD"] ? data.product_totals.scraps["Slim Privacy HD"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.scraps["Slim UV"] ? data.product_totals.scraps["Slim UV"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim UV</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.scraps["Slim Matt"] ? data.product_totals.scraps["Slim Matt"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          </div>
        </div>
        <div className='statRow' style={{ borderColor: primaryColor, display: "block"}}>

        <div style={{fontSize: "13px", backgroundColor: primaryColor, color: "white", padding: "5px", borderRadius: "15px"}}>Csere</div>
        <div style={{display: "inline-flex"}}>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.replaces["Slim HD"] ? data.product_totals.replaces["Slim HD"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.replaces["Slim Self-healing"] ? data.product_totals.replaces["Slim Self-healing"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Self-healing</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.replaces["Slim Privacy Matt"] ? data.product_totals.replaces["Slim Privacy Matt"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.replaces["Slim Privacy HD"] ? data.product_totals.replaces["Slim Privacy HD"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.replaces["Slim UV"] ? data.product_totals.replaces["Slim UV"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim UV</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.replaces["Slim Matt"] ? data.product_totals.replaces["Slim Matt"] : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div></div>
        </div>

        <div className='statRow' style={{ borderColor: primaryColor, display: "block"}}>

        <div style={{fontSize: "13px", backgroundColor: primaryColor, color: "white", padding: "5px", borderRadius: "15px"}}>Kártyával beváltott termékek</div>
        <div style={{display: "inline-flex"}}>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.voucheredCard["Slim HD"] ? data.product_totals.voucheredCard["Slim HD"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.voucheredCard["Slim Self-healing"] ? data.product_totals.voucheredCard["Slim Self-healing"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Self-healing</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.voucheredCard["Slim Privacy Matt"] ? data.product_totals.voucheredCard["Slim Privacy Matt"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv'>
            <p className='paymentQty'>{data.product_totals.voucheredCard["Slim Privacy HD"] ? data.product_totals.voucheredCard["Slim Privacy HD"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.voucheredCard["Slim UV"] ? data.product_totals.voucheredCard["Slim UV"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim UV</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{data.product_totals.voucheredCard["Slim Matt"] ? data.product_totals.voucheredCard["Slim Matt"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
          <p className='paymentQty'>{data.product_totals.voucheredCard["Tok"] ? data.product_totals.voucheredCard["Tok"].total : 0} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Tok</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHammer} />
            <br></br>
            <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>
            {data.product_totals.voucheredCard &&
        Object.entries(data.product_totals.voucheredCard)
        .filter(([itemType]) => itemType === "Tok")
        .map(([itemType, itemData]) => (
          <div class="centerDiv" key={itemType}>
            <h2 class="statTitle">{itemType} ({itemData.total} db)</h2>
      
            {itemData.mobile_types && Object.keys(itemData.mobile_types).length > 0 ? (
              <div >
                {Object.entries(itemData.mobile_types).map(([mobileType, count]) => (
                  <div>
                  <p class="productType pay">{count} db - {mobileType} </p>
                  </div>
                ))}
              </div>
            ) : (
              <p>Nincs elérhető mobil típus.</p>
            )}
          </div>
        ))}
            </Popup>
          </div>
          </div>
        </div>
        </div>
        
  </div>
) : (
  <div></div>
)}

        
          
    </div>
  );

}