import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);

//PROFILE KEZDETE//
// API lekérdezés a profilhoz
export async function getProfileById(id) {
  try {
    
    const response = await apiManager.get('/profile/?userId=' + id );
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

// API hívás a profil létrehozáshoz
export async function addProfile(username, password, jobrole) {
  try {
    const response = await apiManager.post('/register/', {"username": username, "password": password, "realname": username, "jobrole": jobrole});
    if (response.status === 201) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

export async function addPoint(userid, type, pointNm, pointDescription) {
  try {
    console.log("userid: " + userid + " type: " + type + " pointNm: " + pointNm + " pointDescription: " + pointDescription);
    const response = await apiManager.post('/point/point-add/', {"user_id": userid, "typeOfPoint": type, "amount": pointNm, "description": pointDescription});
    if (response.status === 201) {
        return true;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}
//PROFILE VÉGE//