import React, { useState, useEffect } from "react";
import {
  getFinance,
  postDaily,
  deleteDaily,
  updateFixedExpense
} from "../../Services/Api/FinanceApi";
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: auto;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
`;


const Input = styled.input`
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
`;

const ToggleGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const ToggleButton = styled.button`
  background-color: ${props => props.selected ? "#28a745" : "#fff"};
  color: ${props => props.selected ? "#fff" : "#28a745"};
  border: 1px solid #28a745;
  border-radius: 4px;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${props => props.selected ? "#218838" : "#e9f7ef"};
  }
`;

const DateInput = styled(Input)`
  appearance: none;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SectionTitle = styled.button`
  cursor: pointer;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;

  color: dimgray;
  font-size: 1.0em;
  padding: 10px 5px;
  text-align: left;
  width: 100%;
  transition: color 0.3s, background-color 0.3s;
`;

const SectionContent = styled.div`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    
    th, td {
      padding: 8px;
      text-align: left;
      border: 1px solid #ddd;
    }
    
    th {
      background-color: #f2f2f2;
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background-color: #218838;
  }
`;

const Summary = styled.div`
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-weight: bold;
  text-align: center;
`;

const FinancialSummary = () => {
  const [data, setData] = useState(null);
  const [newDailyExpense, setNewDailyExpense] = useState({ description: "", amount: "" });
  const [expandedSections, setExpandedSections] = useState({});
  const [viewType, setViewType] = useState("monthly");
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editedFixedExpenses, setEditedFixedExpenses] = useState({});
  const [editingFixedExpense, setEditingFixedExpense] = useState(null);

  useEffect(() => {

    fetchData();
  }, [selectedMonth, selectedDate, viewType]);

  const fetchData = async () => {
    if (selectedMonth || selectedDate) {
      try {
        const queryParam =
          viewType === "monthly"
            ? `yearmonth=${selectedMonth.getFullYear()}-${(selectedMonth.getMonth() + 1).toString().padStart(2, "0")}`
            : `date=${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}`;
        const response = await getFinance(queryParam);
        setData(response);
      } catch (error) {
        console.error("Error fetching finance data:", error);
      }
    }
  };
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const addDailyExpense = async () => {
    if (!newDailyExpense.description || !newDailyExpense.amount) return;

    try {
      await postDaily({
        date: selectedDate.toISOString().split("T")[0],
        description: newDailyExpense.description,
        amount: parseFloat(newDailyExpense.amount),
      });
      fetchData();
      setNewDailyExpense({ description: "", amount: "" });
    } catch (error) {
      window.alert("Hiba történt a feltöltéskor!")
      console.error("Error adding daily expense:", error);
    }
  };

  const handleDeleteDailyExpense = async (expenseId) => {
    if (window.confirm("Biztosan törlöd a kiadást?")) {
      try {
        await deleteDaily(expenseId);
        fetchData();
      } catch (error) {
        console.error("Error deleting daily expense", error);
        window.alert("Hiba történt a törlés során!");
      }
    }
  };

  const handleUpdateFixedExpense = async (expenseId) => {
    const newAmount = editedFixedExpenses[expenseId];
    if (newAmount === undefined) return;
    try {
      await updateFixedExpense(expenseId, parseFloat(newAmount));
      fetchData();
      setEditingFixedExpense(null);
      setEditedFixedExpenses(prev => {
        const newState = { ...prev };
        delete newState[expenseId];
        return newState;
      });
    } catch (error) {
      console.error("Error updating fixed expense", error);
      window.alert("Hiba történt a frissítés során!");
    }
  };

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    if (viewType === "monthly") {
      setSelectedMonth(date);
    } else {
      setSelectedDate(date);
    }
  };

  const calculateProfit = () => {
    if (data) {
      const totalRevenueNet = data.total_revenue_net;
      const totalCost = data.total_cost;
      const totalExpenses = data.expenses.total_expense;
      const scrapCost = data.scrap_cost;
      const totalSalaries = data.promoter_payments.reduce((sum, p) => sum + p.fizetes, 0);
      return totalRevenueNet - (totalCost + scrapCost + totalExpenses + totalSalaries);
    }
    return 0;
  };

  if (!data) return <p>Adatok betöltése...</p>;

  return (
    <Container>
      <Title>Pénzügyi Összesítő</Title>
      <ToggleGroup>
        <ToggleButton selected={viewType === "monthly"} onClick={() => setViewType("monthly")}>
          Havi nézet
        </ToggleButton>
        <ToggleButton selected={viewType === "daily"} onClick={() => setViewType("daily")}>
          Napi nézet
        </ToggleButton>
      </ToggleGroup>
      {viewType === "monthly" ? (
        <DateInput
          type="month"
          value={`${selectedMonth.getFullYear()}-${(selectedMonth.getMonth() + 1).toString().padStart(2, "0")}`}
          onChange={handleDateChange}
        />
      ) : (
        <DateInput
          type="date"
          value={selectedDate.toISOString().split("T")[0]}
          onChange={handleDateChange}
        />
      )}

      <div>
        <SectionTitle onClick={() => toggleSection("revenue")}>📈 Bevételek {expandedSections.revenue ? "▲" : "▼"}</SectionTitle>
        {expandedSections.revenue && (
          <SectionContent>
            <table>
              <thead>
                <tr>
                  <th>Bruttó bevétel</th>
                  <th>Nettó bevétel</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.total_revenue.toLocaleString()} Ft</td>
                  <td>{data.total_revenue_net.toLocaleString()} Ft</td>
                </tr>
              </tbody>
            </table>
          </SectionContent>
        )}
      </div>

      <div>
        <SectionTitle onClick={() => toggleSection("productCosts")}>🛒 Termékköltségek ({data.total_cost.toLocaleString()} Ft) {expandedSections.productCosts ? "▲" : "▼"}</SectionTitle>
        {expandedSections.productCosts && (
          <SectionContent>
            <table>
              <thead>
                <tr>
                  <th>Termék neve</th>
                  <th>Darab</th>
                  <th>Költség</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.products).map(([name, details]) => (
                  <tr key={name}>
                    <td>{name}</td>
                    <td>{details.db} db</td>
                    <td>{details.ft.toLocaleString()} Ft</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </SectionContent>
        )}
      </div>

      <div>
        <SectionTitle onClick={() => toggleSection("fixedExpenses")}>💸 Havi Fix Költségek ({data.expenses.fixed_expense_total.toLocaleString()} Ft) {expandedSections.fixedExpenses ? "▲" : "▼"}</SectionTitle>
        {expandedSections.fixedExpenses && (
          <SectionContent>
            <table>
              <thead>
                <tr>
                  <th>Költség neve</th>
                  <th>Összeg</th>
                </tr>
              </thead>
              <tbody>
                {data.expenses.fixed_expenses.map((expense) => (
                  <tr key={expense.id}>
                    <td>{expense.description}</td>
                    <td>
                      {viewType === "monthly" ? (
                        editingFixedExpense === expense.id ? (
                          <>
                            <Input
                              type="number"
                              value={editedFixedExpenses[expense.id] !== undefined ? editedFixedExpenses[expense.id] : expense.amount}
                              onChange={(e) => setEditedFixedExpenses(prev => ({ ...prev, [expense.id]: e.target.value }))}
                            />
                            <Button onClick={() => handleUpdateFixedExpense(expense.id)}>Mentés</Button>
                          </>
                        ) : (
                          <span
                            onClick={() => {
                              setEditingFixedExpense(expense.id);
                              setEditedFixedExpenses(prev => ({ ...prev, [expense.id]: expense.amount }));
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {expense.amount.toLocaleString()} Ft
                          </span>
                        )
                      ) : (
                        `${expense.amount.toLocaleString()} Ft`
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </SectionContent>
        )}
      </div>

      <div>
        <SectionTitle onClick={() => toggleSection("dailyExpenses")}>📅 Napi Kiadások ({data.expenses.daily_expense_total.toLocaleString()} Ft) {expandedSections.dailyExpenses ? "▲" : "▼"}</SectionTitle>
        {expandedSections.dailyExpenses && (
          <SectionContent>
            <table>
              <thead>
                <tr>
                  <th>Kiadás neve</th>
                  <th>Összeg</th>
                </tr>
              </thead>
              <tbody>
                {data.expenses.daily_expenses.length > 0 ? (
                  data.expenses.daily_expenses.map((expense) => (
                    <tr key={expense.id} onClick={() => handleDeleteDailyExpense(expense.id)} style={{ cursor: 'pointer' }}>
                      <td>{expense.description}</td>
                      <td>{expense.amount.toLocaleString()} Ft</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">Nincsenek napi kiadások.</td>
                  </tr>
                )}
              </tbody>
            </table>

            {viewType === "daily" && (
              <div>
                <Input
                  type="text"
                  placeholder="Kiadás neve"
                  value={newDailyExpense.description}
                  onChange={(e) => setNewDailyExpense((prev) => ({ ...prev, description: e.target.value }))}
                />
                <Input
                  type="number"
                  placeholder="Összeg (HUF)"
                  value={newDailyExpense.amount}
                  onChange={(e) => setNewDailyExpense((prev) => ({ ...prev, amount: e.target.value }))}
                />
                <Button onClick={addDailyExpense}>Hozzáadás</Button>
              </div>
            )}
          </SectionContent>
        )}
      </div>

      <div>
        <SectionTitle onClick={() => toggleSection("scrapCost")}>🗑️ Selejt ({data.scrap_cost.toLocaleString()} Ft) {expandedSections.scrapCost ? "▲" : "▼"}</SectionTitle>
        {expandedSections.scrapCost && (
          <SectionContent>
            <p>Selejtek: {data.scrap_cost.toLocaleString()} Ft</p>
          </SectionContent>
        )}
      </div>
      
      <div>
        <SectionTitle onClick={() => toggleSection("salaries")}>👩‍💼 Fizetések ({data.promoter_payments.reduce((sum, p) => sum + p.fizetes, 0).toLocaleString()} Ft) {expandedSections.salaries ? "▲" : "▼"}</SectionTitle>
        {expandedSections.salaries && (
          <SectionContent>
            <table>
              <thead>
                <tr>
                  <th>Név</th>
                  <th>Fizetés</th>
                </tr>
              </thead>
              <tbody>
                {data.promoter_payments
                  .filter((p) => p.fizetes > 0)
                  .map((p) => (
                    <tr key={p.name}>
                      <td>{p.name}</td>
                      <td>{p.fizetes.toLocaleString()} Ft</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </SectionContent>
        )}
      </div>

      <Summary>
        Összes profit: {calculateProfit().toLocaleString()} Ft
      </Summary>
    </Container>
  );
};

export default FinancialSummary;