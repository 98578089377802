import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";
import Popup from 'reactjs-popup';
import { ThemeContext } from '../App/ThemeContext';
import { handlePoint } from "../../Services/Api/UserApi";
import { getStatforMasters } from '../../Services/Api/StatApi';
import 'reactjs-popup/dist/index.css';
import {
  getEveryPromoter,
} from "../../Services/Api/PromoterApi";

export default function Intern() {
  const isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
    const tableStyles = {
        display: "flex",
        flexDirection: "column",
      };
      
      const rowStylesOrange = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#fdcb6e"
      };
      const rowStylesRed = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#ff7675"
      };
      const rowStylesGreen = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#00b894",
        color: "white"
      };
      
      const columnStyles = {
        flexBasis: "10%",
      };
      const headerStyles = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        fontWeight: 'bold',
        padding: '10px',
        color: 'black',
        textAlign: 'center',
        fontSize: '14px'
      };
      
  const [allData, setAllData] = useState([])
  const isPointSystem = true;
  const [isLoading, setIsLoading] = useState(false);
  const [promoters, setAllPromoters] = useState([])
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(`2025-${currentMonth.toString().padStart(2, '0')}`)
  const isCurrentMonthSelected = selectedMonth === `2025-${currentMonth.toString().padStart(2, '0')}`;
  const currentDateMonth = new Date("2024-03");
const inputDate = new Date(selectedMonth);
const [isAddingPoint, setIsAddingPoint] = useState(false);
const [isRemovingPoint, setIsRemovingPoint] = useState(false);
const [pointSystemData, setPointSystemData] = useState({});

  const [removePointAmount, setRemovePointAmount] = useState(0);
  const [addPointAmount, setAddPointAmount] = useState(0);

  useEffect(() => {
    fetchPromoters();
    fetchData()
  }, []);

  const fetchPromoters = () => {
      getEveryPromoter().then((response) => {
        setAllPromoters(response.filter(item => item.username !== "Super Slimmer" && item.username !== "Slim Store" && item.username !== "László Emma" && item.username !== "Zentai Zsófi").filter(item => item.jobrole == "intern").filter(item => item.isActive == 1))
      })
  }
  const fetchData = async () => {
    fetchPromoters();
    fetchPoints(selectedMonth);
  };

  const fetchPoints = async (time) => {
    setAllData([]);
    setIsLoading(true);
    
    try {
        const response = await getStatforMasters(time);
        let promotersList = promoters
        const transformedData = {};
        // Várjuk meg, amíg a promoters betöltődik
        if (promotersList.length === 0) {
          const fetchedPromoters = await getEveryPromoter();
          promotersList = fetchedPromoters
            .filter(item => item.username !== "Super Slimmer" && item.username !== "Slim Store" && item.username !== "László Emma" && item.username !== "Zentai Zsófi" && item.username !== "Super Slimmer")
            .filter(item => item.jobrole == "intern")
            .filter(user => user.isActive === 1);
            setAllPromoters(promotersList);
        }

        
        Object.entries(response.user_totals).forEach(([userId, data]) => {
            const promoter = promotersList.find(p => p.id === parseInt(userId, 10));
            if (promoter) {
                transformedData[promoter.username] = {
                    total: data.total,
                    daily: data.daily
                };
            }
        });

        setPointSystemData(transformedData);
    } catch (error) {
        console.error("Hiba történt a fetchPoints függvényben:", error);
    } finally {
        setIsLoading(false);
    }
  };
  const renderDaysPopupforPoint = (alldataofValue) => {
    return (
      <div>
        {Object.entries(alldataofValue).map(([key, value]) => (
          <div key={key} className='row'>
            <div className='column leftcolumn'>
              {key}
            </div>
            <div className='column'>
              {value}
            </div>
          </div>
        ))}
      </div>
    );
  };
  
 const getUserProfilePic = (username) => {
    const foundUser = promoters.find(obj => obj.username === username.replace(/^\s+|\s+$/g, ''));
    var profilePic = "https://leltar.slimstore.hu/" + "profile_images/placeholder.png";
    if(foundUser != null && foundUser.profile_pic != ""){
      profilePic = "https://leltar.slimstore.hu/" + "profile_images/" + foundUser.profile_pic;
    }
    return profilePic;
  }
  
  const getUserId = (username) => {
    const foundUser = promoters.find(obj => obj.username === username.replace(/^\s+|\s+$/g, ''));
    if(foundUser != null){
      return foundUser.id;
    }
  }
  const handleRemovePoint = async (userId) => {
    if (isRemovingPoint) return;
    setIsRemovingPoint(true);
    try {
      const success = await handlePoint(userId, removePointAmount, false);
      if (success) {
          setRemovePointAmount(0);
          setAddPointAmount(0);
          window.alert("Pont levonva");
          fetchPoints(selectedMonth);
      }
      setIsRemovingPoint(false);
      return success;
    } catch (error) {
      console.error("Hiba történt a pont levonásakor", error);
      setIsRemovingPoint(false);
      return false;
    }
  }



  const handleAddPoint = async (userId) => {
    if (isAddingPoint) return;
    setIsAddingPoint(true);
    try {
      const success = await handlePoint(userId, addPointAmount, true);
      if (success) {
          setAddPointAmount(0);
          setRemovePointAmount(0);
          window.alert("Pont hozzáadva");
          fetchPoints(selectedMonth);
      }
      setIsAddingPoint(false);
      return success;
    } catch (error) {
      console.error("Hiba történt a pont hozzáadásakor", error);
      setIsAddingPoint(false);
      return false;
    }
  }
const formatPrice = (value) => {
  // Convert the plain number to a numeric value
  const numericValue = parseFloat(value);
  // Check if the numeric value is a valid number
  if (!isNaN(numericValue)) {
    // Use toLocaleString to format the number with spaces as thousand separators
    return numericValue.toLocaleString('hu');
  } else {
    // Return the original value if it's not a valid number
    return value;
  }
};
function fizetes (days) {
  var value = 0;
  value = 20000 * days
  return value
  }

  const getJobName = (jobrole, foundUser, groupedData, totalValueNormalized) => {
    if (groupedData != null) {
    }
    switch (jobrole) {
      case "fulltime":
        return "Teljes munkaidős"
      case "parttime":
        return "Részmunkaidős"
      case "intern":
        return "Betanuló"
      default:
        return "Inaktív"
    }
  }
  const internPhase = (foundUser, groupedData, totalValueNormalized) => {
    if(foundUser == null || groupedData == null){
      return;
    } else {
    if (groupedData.length < 10 && totalValueNormalized < 100) {
    return 1;
  } else if (totalValueNormalized > 100 && totalValueNormalized < 150) {
    return 2;
  } else {
    return 3;
  }
}
  }
  function getColorByJob(qty) {
    if (qty < 200) {
    return rowStylesRed;
  } else if (qty < 300) {
    return rowStylesOrange;
  } else {
    return rowStylesGreen;
  }
}

  const renderPointSystem = () => {
    
    if (isPointSystem && isLoading) {
      return <div className="loader" style={{ borderTopColor: primaryColor }} />;
    }
  
    if (isPointSystem && !isLoading) {

      let decoded = jwt_decode(localStorage.getItem("accessToken"));
      let isAdmin = decoded.priv == 1 || decoded.priv == 0;
  
      // Az adatok rendezése computed_units szerint csökkenő sorrendben
      const sortedUsers = Object.entries(pointSystemData)
        .map(([username, data]) => ({ username, ...data }))
        .sort((a, b) => b.total - a.total);
    return <div>
              <div>
          {sortedUsers.map((user, index) => (
            <div key={user.username} style={getColorByJob(user.total)}>
            <a href={`/guestprofile/${getUserId(user.username)}`}>
            <div>
              {<img className='profilepicinMasters' src={getUserProfilePic(user.username)} alt="Profile Picture" />}
            </div>
          </a>
            <div >{user.username}</div>
            <div>Betanuló</div>
            <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Összesen: </p>{user.total} pont<Popup trigger={<button className='infobutton morebutton'>
            <FontAwesomeIcon className='faiconinfo' icon={faInfoCircle} /> Részletes
            </button>} modal>
        { <div>{renderDaysPopupforPoint((user.daily))}</div> }
        </Popup></div>
        <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Átlag/nap: </p>{(user.total / Object.entries(user.daily).length).toFixed(2)} pont</div>
            <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Fizetés: </p>
              {formatPrice(fizetes(Object.entries(user.daily).length))} Ft
            </div>
            <hr className='hrc onlyonMobile'/>
            <div style={columnStyles}><p className='onlyonMobile'>Bónusz: </p>
              
              <br></br>
              {/* <div>{ getBonuses(getUserId(user.username))}</div> */}
              <Popup 
        trigger={isAdmin && 
          <button 
            style={{
              backgroundColor: "#3498db", 
              color: "white", 
              border: "none", 
              padding: "10px 15px", 
              borderRadius: "5px", 
              fontSize: "14px", 
              cursor: "pointer",
              width: isMobile ? "100%" : "auto",
              display: "block",
              marginTop: "10px"
            }}
          >
            Pont hozzáadása
          </button>
        } 
        modal
        contentStyle={{
          backgroundColor: "transparent", // Háttérszín megadása
          padding: "20px",
          border: "none",
          height: "100px",
          borderRadius: "10px"
        }}
      >
        {close => (
          <div style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: isMobile ? "90%" : "400px",
            width: "90%",
            margin: "auto",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            textAlign: "center",
            zIndex: 1000 // Popup mindig legyen felül
          }}>
            <button 
              onClick={close} 
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                fontSize: "20px",
                cursor: "pointer"
              }}
            >
        ✖
      </button>
      <div>
        <h2 style={{ fontSize: "18px", marginBottom: "15px" }}>Pont hozzáadása</h2>
        <div style={{ textAlign: "left" }}>
          <label style={{ fontWeight: "bold", fontSize: "14px", display: "block", marginBottom: "5px" }}>Pont mértéke</label>
          <input 
            style={{
              borderRadius: "10px",
              border: "1px solid black",
              padding: "8px",
              width: "100%",
              fontSize: "14px"
            }}
            type="number" 
            value={addPointAmount} 
            onChange={(e) => setAddPointAmount(e.target.value)} 
          />
        </div>
        <br />
          <button 
            style={{
              width: "100%", 
              height: "40px", 
              backgroundColor: "#27ae60", 
              color: "white", 
              border: "none", 
              borderRadius: "5px", 
              fontSize: "14px",
              cursor: isAddingPoint ? "not-allowed" : "pointer",
              marginTop: "10px"
            }} 
            disabled={isAddingPoint}
            onClick={async () => {
              const success = await handleAddPoint(getUserId(user.username));
              if (success) close();
            }}
          >
            {isAddingPoint ? "Hozzáadás..." : "Pont hozzáadása"}
          </button>
      </div>
    </div>
  )}
</Popup>
<Popup 
  trigger={isAdmin && 
    <button 
      style={{
        backgroundColor: "red", 
        color: "white", 
        border: "none", 
        padding: "10px 15px", 
        borderRadius: "5px", 
        fontSize: "14px", 
        cursor: "pointer",
        width: isMobile ? "100%" : "auto",
        display: "block",
        marginTop: "10px"
      }}
    >
      Pont levonása
    </button>
  } 
  modal
  contentStyle={{
    backgroundColor: "transparent", // Háttérszín megadása
    padding: "20px",
    border: "none",
    height: "100px",
    borderRadius: "10px"
  }}
>
  {close => (
    <div style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: isMobile ? "90%" : "400px",
      width: "90%",
      margin: "auto",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
      textAlign: "center",
      zIndex: 1000 // Popup mindig legyen felül
    }}>
      <button 
        onClick={close} 
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          fontSize: "20px",
          cursor: "pointer"
        }}
      >
        ✖
      </button>
      <div>
        <h2 style={{ fontSize: "18px", marginBottom: "15px" }}>Pont levonása</h2>
        <div style={{ textAlign: "left" }}>
          <label style={{ fontWeight: "bold", fontSize: "14px", display: "block", marginBottom: "5px" }}>Pont mértéke</label>
          <input 
            style={{
              borderRadius: "10px",
              border: "1px solid black",
              padding: "8px",
              width: "100%",
              fontSize: "14px"
            }}
            type="number" 
            value={removePointAmount} 
            onChange={(e) => setRemovePointAmount(e.target.value)} 
          />
        </div>
        <br />
          <button 
          style={{
            width: "100%", 
            height: "40px", 
            backgroundColor: "red", 
            color: "white", 
            border: "none", 
            borderRadius: "5px", 
            fontSize: "14px",
            cursor: isRemovingPoint ? "not-allowed" : "pointer",
            marginTop: "10px"
          }} 
          disabled={isRemovingPoint}
          onClick={async () => {
            const success = await handleRemovePoint(getUserId(user.username));
            if (success) close();
          }}
        >
          {isRemovingPoint ? "Levonás..." : "Pont levonása"}
        </button>
      </div>
    </div>
  )}
</Popup>
            </div>
          </div>
          ))}
        </div>
      </div>;}
  };
  return(
    // Rendering the sorted table-like layout
<div id='masters'>
  <h2>Betanulók</h2>
      
  <div style={tableStyles}>
  <div style={headerStyles} className='onlyonPC'>
    <div></div>
    <div>Név</div>
    <div>Összes</div>
    <div>Átlag/nap</div>
    <div>Fizetés</div>
  </div>
  {renderPointSystem()}
  </div>
</div>
  )

}


