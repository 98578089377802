import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);

//ELSZÁMOLÁS KEZDETE//
// API lekérdezés a profilhoz
export async function getSlimmersIntern(starDate, endDate) {
  try {
    const response = await apiManager.get('/saved-slimmers/?start_date=' + starDate + '&end_date=' + endDate);
    if (response.status === 200) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

// API hívás a profil létrehozáshoz
export async function postSlimmerIntern(email) {
  try {
    const response = await apiManager.post('/saved-slimmers/', {"slimmer_email": email});
    if (response.status === 201) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}
//ELSZÁMOLÁS VÉGE//