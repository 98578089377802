import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import './New_item.css';
import jwt_decode from "jwt-decode";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import Cookies from 'universal-cookie';
import { ThemeContext } from '../App/ThemeContext';
import { Scanner } from '@yudiel/react-qr-scanner';
import { getLocationsAndItemTypes, postNewItemToStorage, postQRcode, getItemsFromStorage, getWarrantyByImei } from '../../Services/Api/StorageApi';
import {
  removeInventory,
} from "../../Services/Api/InventoryApi";
import { addPoint } from '../../Services/Api/ProfileApi';

export default function New_item() {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const warranty = "0";
  const [email, setEmail] = useState("");
  const [itemList, setList] = useState([])
  const [selectedItemList, setSelectedItemList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSlimmer, setIsSlimmer] = useState(false)
  const [userData, setUserData] = useState([])
  const [itemsTitle, setTitle] = useState("Nincs hozzáadott termék")
  const [isWarrantyPurchase, setIsWarrantyPurchase] = useState(false)
  const [showImeiInput, setShowImeiInput] = useState(false)
  const [imei, setImei] = useState("")
  const cookies = useMemo(() => new Cookies(), []);
  const include = selectedItemList.some(val => val.itemType.includes("kártya"));
  const includeCleaning = selectedItemList.some(val => val.itemType.includes("Tisztítás"));
  const onlyCleaningCards = selectedItemList.every(val => 
    val.itemType.includes("kártya") ? val.itemType.includes("Tisztítás") : true
  );
  const [showQrReader, setShowQrRead] = useState(false);
  const [showQrReaderItem, setShowQrReadItem] = useState(false);
  const [inputMode, setInputMode] = useState("location");

// Kapcsoló kezelése
const handleModeChange = (mode) => {
  setInputMode(mode);
};
  const setShowQrReader = () => {
    setShowQrRead(!showQrReader);
  };
  const setShowQrReaderItem = () => {
    setShowQrReadItem(!showQrReaderItem);
  };
  // Delay függvény (Promise alapú sleep)
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const handleSubmit = async (event) => {
  event.preventDefault();
  setIsLoading(true);
      const decoded = jwt_decode(localStorage.getItem("accessToken"));
      
  if (selectedItemList.length > 0) {
    try {
      // Előzetes adatok feldolgozása
      var selectedItemListNew = selectedItemList.map(val => {
        const element = itemList.find(item => item.itemName === val.itemType);
        val.itemPrice = getPrice(val.itemType);
        val.slimmerPoint = element.slimmerPoint;
        val.stockPoint = element.stockPoint;
        return val;
      });

      // Ellenőrzés kártyákra
      var cardStrings = [];
      var index = 1;
      selectedItemListNew.forEach(element => {
        if (element.itemType.includes("kártya")) {
          var data1 = {
            id: index,
            location: location,
            cardType: element.itemType,
            price: getPrice(element.itemType),
            timestamp: new Date().toISOString(),
          };
          var encodedData = encodeData(JSON.stringify(data1));
          cardStrings.push(encodedData);
          index++;
        }
      });

      if (include && selectedItemListNew.filter(val => val.itemType.includes("kártya")).length !== cardStrings.length) {
        alert("Kérlek add meg a kártyák árát!");
        return;
      }
      
      const inventoryPlace = location.includes("Westend") ? "Westend" : "Iroda"
      // RemoveInventory hívások delay-jel
      for (const element of selectedItemList) {
        await removeInventory(inventoryPlace, element.itemType, element.mobileType);
        await delay(200); // 200ms késleltetés a hívások között
      }
      // POST adatok előkészítése
      var decodedUser = jwt_decode(localStorage.getItem("accessToken"));

      var today = new Date(),
        stringDate =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate() +
          " " +
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();

      var body = {
        time: stringDate,
        location: location,
        price: getAllPrice(),
        paymentType: type,
        user: decodedUser.id,
        userName: decodedUser.name,
        warranty: 0,
        items: selectedItemList,
        isLeader: 0,
      };

      if (isWarrantyPurchase) {
        body.warrantyReplace = imei;
      }
      if (isSlimmer) {
        body.slimmerEmail = userData["Email"];
      }
      
      if (include) {
        const postPromises = cardStrings.map((item) => {
          const decodedQrString = decodeData(item); // Dekódolás
          const body = { qrstring: item, email: email };
        
          if (decodedQrString.includes("Tisztítás")) {
            if (inputMode === "qr") {
              return postQRcode(body); // Küldés a szervernek
            }
            return null; // Ha nem teljesül a feltétel, akkor explicit `null`-t adunk vissza
          } else {
            return postQRcode(body); // Küldés a szervernek
          }
        });
        
        await Promise.all(postPromises);
    };
      // POST kérés a szerverhez
      await postNewItemToStorage(body, () => {
        setIsLoading(false);
        alert("Eladás rögzítve!");
        window.location.reload();
      });
    } catch (error) {
      console.error("Hiba történt a folyamat során:", error);
      setIsLoading(false);
      alert("Hiba történt az eladás rögzítésekor!");
    }
  } else {
    alert("Adj hozzá legalább 1 terméket!");
  }
};
    

    function encodeData(data) {
      // UTF-8 kódolás helyes kezelése
      let utf8Data = new TextEncoder().encode(data);
      // Base64 kódolás
      let base64 = btoa(String.fromCharCode.apply(null, utf8Data));
      // URL-barát base64
      return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }
function getPrice(item) {
  const element = itemList.find(element => element.itemName === item);
  if (element) {
    if(isWarrantyPurchase) {
      return 7500;
    } else {
      if (isSlimmer) {
        return element.slimmerPrice;
      } else {
        return element.regularPrice;
      }
    }
    
  }
}
function getAllPrice() {
  var totalPrice = 0;
  if(isWarrantyPurchase) {
    if(selectedItemList.length > 0) {
      totalPrice = 7000;
    }
  } else {
    if(isSlimmer){
      warranty === "1" ? totalPrice += 18000 : warranty === "2" ? totalPrice += 30000 : totalPrice += 0;
    } else {
      warranty === "1" ? totalPrice += 24000 : warranty === "2" ? totalPrice += 36000 : totalPrice += 0;
    }
    for (let itemen of selectedItemList) {
      for (let element of itemList) {
        if (element.itemName === itemen.itemType) {
          if (isSlimmer) {
            totalPrice += parseInt(element.slimmerPrice);
          } else {
            totalPrice += parseInt(element.regularPrice);
          }
          break;  // Exit the inner loop once a match is found
        }
      }
    }
  }
  
  return totalPrice;  // Return the total price after all items are processed
}
const handleScan = (data) => {
  if (data) {
    let decodedData = decodeData(data)
    let json = JSON.parse(decodedData)
    if (json["Name"]) {
      let time = new Date();
      time.setHours(time.getHours() + 1);
      let year = time.getFullYear();
      let month = String(time.getMonth() + 1).padStart(2, '0');
      let day = String(time.getDate()).padStart(2, '0');
      let formattedDate = `${year}-${month}-${day}`;
      getItemsFromStorage(formattedDate, formattedDate).then((data) => {
        if(data.items.filter(item => item.slimmerEmail === json["Email"]).length > 0) {
          alert("Ez a felhasználó már használta a Slimmer kódját ma!")
          setShowQrReader(false)
          return  
        } else {
          setUserData(json)
          setEmail(json["Email"])
          setIsSlimmer(true)
          setShowQrReader(false)
        };
      });
      
    } else {
      setIsSlimmer(false)
      setEmail("")
      alert("Érvénytelen Slimmer QR kód!")
      setShowQrReader(false)
    }

  }
};
const handleScanItem = (data) => {
  if (data) {
    if (data.length < 80) {
      if(data.includes("-") && data !== "Slim Self-healing") {
        let splitData = data.split("-")
        handleAddItem(splitData[0].slice(0, -1), splitData[1].slice(1));
        setShowQrReadItem(false)
      } else {
        handleAddItem(data, "-");
        setShowQrReadItem(false)
      }
    } else {
      window.alert("Hibás QR termékkód!")
      setShowQrReadItem(false)
    }
  }
};
const handleImeiWarranty = async () => {
  try {
    getWarrantyByImei(imei).then(data => {
      if(data.length > 0) {
        let isValid = data.some(item =>
          item.garanciaType.includes("Előlapi") && item.isvalid === 1
        );
        if(isValid) {
          setIsWarrantyPurchase(true)
        } else {
          alert("Erre a garanciára nem tudsz garanciális cserét tenni, ellenőrizd az IMEI számot!")
          setIsWarrantyPurchase(false)
        }
      }})
  } catch (error) {
    console.error('Error searching data:', error);
  }
}

    function decodeData(base64) {
      // URL-barát base64 visszaalakítása normál base64 formátumra
      let base64String = base64.replace(/-/g, '+').replace(/_/g, '/');
      // Base64 dekódolás
      let utf8Data = atob(base64String);
      // UTF-8 dekódolás
      return decodeURIComponent(escape(utf8Data));
    }
  const handleAddItem = (itemType, mobileType) => {
    if(isWarrantyPurchase && selectedItemList.length > 0) {
      alert("Garanciális cserét csak egy termékre tudsz felvinni!")
      return
    }
    if (mobileType !== "" & itemType !== "") {
      var mobileTypeOk = ""
      var itemTypeOk = ""
      if(itemType === "Slim Cas") {
        itemTypeOk = "Slim Case"
      } else {
        itemTypeOk = itemType
      }
      if(mobileType === "Phone 7/8") {
        mobileTypeOk = "iPhone 7/8"
      } else {
        mobileTypeOk = mobileType
      }
      let item = {id: selectedItemList.length + 1, itemType: itemTypeOk, mobileType: mobileTypeOk}
    let newItem = selectedItemList.concat(item)
      setSelectedItemList(newItem)
      if (newItem.length > 0) {
      setTitle("Hozzáadott termékek:")
    } else {
      setTitle("Nincs hozzáadott termék")
    }
    } else {
      alert("Hibás QR termékkód!")
    }
  }

  const handleRemove = id => {
    const items = selectedItemList.filter((item) => item.id !== parseInt(id))
    setSelectedItemList(items)
    if (items.length > 0) {
      setTitle("Hozzáadott termékek:")
    } else {
      setTitle("Nincs hozzáadott termék")
    }
  }   
  const handleLocation = location => {
    setLocation(location)
    let d = new Date();
      d.setTime(d.getTime() + (720*60*1000));
    cookies.set('location', location, { path: '/', expires: d });
  } 
  const formatPrice = (value) => {
    // Convert the plain number to a numeric value
    const numericValue = parseFloat(value);
    // Check if the numeric value is a valid number
    if (!isNaN(numericValue)) {
      // Use toLocaleString to format the number with spaces as thousand separators
      return numericValue.toLocaleString('hu') + " Ft";
    } else {
      // Return the original value if it's not a valid number
      return value;
    }
  };
  const fetchData = useCallback(() => {
    getLocationsAndItemTypes().then(data => {
      setList(data.itemTypes);
      setLocationList(data.locations);
      setLocation(cookies.get('location'));
    });
  }, [cookies]); // A függőségek listája, ha van rá szükség
  
  useEffect(() => {
    fetchData();
  }, [fetchData]); // A fetchData függvény memoizált, ezért ideális függőség

  const cardList = () => {
      if (include) {
        if ((onlyCleaningCards && inputMode === "qr") || (!onlyCleaningCards)) {
          return (
            <div>
              <h3>Kártyák adatai</h3>
              <p className='inputLabel'>E-mail cím</p>
              <input
                    className='mobileInput'
                    name="email"
                    type="text"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
              required />
              </div>
                )
        } 
      }
      }
  

  return(
    <div>
      <h2 style={{color: primaryColor, marginBottom: "25px"}}>Új eladás felvitele</h2>
      {!isWarrantyPurchase && !showImeiInput && (<button onClick={() => setShowQrReader(!showQrReader)} style={{ marginBottom: "20px", background: primaryColor, color: 'white', padding: '10px', border: 'none', borderRadius: '5px' }}>
        {showQrReader ? 'Slimmer szkenner bezárása' : 'Slimmer szkenner megnyitása'}
      </button>)}
      {isSlimmer && (<div className='Slimmer' style={{background: primaryColor, color: "white", marginBottom: "20px", padding: "10px"}}><h6 style={{margin: "5px"}}>Slimmer kód beolvasva</h6><p style={{fontSize: "15px"}}>{userData["Name"]}</p><p style={{fontSize: "15px"}}>{userData["Email"]}</p></div>)}
      {showQrReader && (
        <Scanner
        legacyMode={true}
        onScan={(result) => handleScan(result[0].rawValue)}
        onError={(error) => window.alert("Hibás QR kód")}
        style={{ width: '100%', height: 'auto' }}
        videoContainerStyle={{ width: '100%', height: 'auto' }} // Add this line
      />
      )}
      <br></br>
      {!isWarrantyPurchase && (<button onClick={() => setShowImeiInput(!showImeiInput)} style={{ marginBottom: "20px", background: showImeiInput ? primaryColor : 'grey', color: 'white', padding: '10px', border: 'none', borderRadius: '5px' }}>
        Garanciális fólia csere
      </button>)}
      
      {showImeiInput && !isWarrantyPurchase && (
        <div>
        <input
         className='mobileInput'
        type="text"
        placeholder="IMEI szám"
        value={imei}
        onChange={e => setImei(e.target.value)}
      />
      <button onClick={() => handleImeiWarranty()} style={{ marginBottom: "20px", background: primaryColor, color: 'white', padding: '10px', border: 'none', borderRadius: '5px' }}>Aktiválás</button>
      </div>
      )}
      {isWarrantyPurchase && (
        <button onClick={() => setIsWarrantyPurchase(false)} style={{ marginBottom: "20px", background: primaryColor, color: 'white', padding: '10px', border: 'none', borderRadius: '5px' }}>Garanciális csere törlése</button>) }
          {isLoading && <div className="loader" style={{ borderTopColor: primaryColor}}></div>}
          {!isLoading && <form onSubmit={handleSubmit}>
              <p className='inputLabel'>Helyszín</p>
              <select className='mobileInput' value={location} onChange={e => handleLocation(e.target.value)} required>
                  <option disabled></option>
                  {locationList.map((val, key) => {
                        return (  
                        <option value={val.locationName}>{val.locationName}</option>
                        )})}
        </select>
        <div className='itemList' style={{borderColor: primaryColor}}>
        <p className='inputLabel' style={{width:"100%"}}>{itemsTitle}</p>
        <div className='items' style={selectedItemList.length < 1 ? {display:"none"}:{}}>
        {selectedItemList.map((val, key) => {
          return (
            <div key={key}>
              <div className="item" style={{paddingLeft: "8px", backgroundColor: primaryColor}} id={val.id} onClick={e => handleRemove(e.target.id)}>{val.itemType}  {val.mobileType !== "-" && " - " + val.mobileType} - {formatPrice(getPrice(val.itemType))} <FontAwesomeIcon className='famenu' aria-hidden="true" icon={faCircleXmark} /></div>
            </div>
            
          )
        })}</div>
        </div>
              <div className='addItemDiv'>
            
        {showQrReaderItem && (
        <Scanner
        legacyMode={true}
        onScan={(result) => handleScanItem(result[0].rawValue)}
        onError={(error) => window.alert("Hibás QR kód")}
        style={{ width: '100%', height: 'auto' }}
        videoContainerStyle={{ width: '100%', height: 'auto' }} // Add this line
      />
      )}
        <button type="button" className='mobileInput submitbutton' style={{backgroundColor: primaryColor}} onClick={() => setShowQrReaderItem(!showQrReaderItem)}>Termék hozzáadása</button>
        </div>
        {include && <hr className='hrc'/>}
        {includeCleaning && (
  <div className="inputModeSwitch">
    <p className="inputLabel">Tisztítások módja:</p>
    <br></br>
    <div className="switchContainer">
      <div
        style={{ background: inputMode === "location" ? primaryColor : "white" }}
        className={`switchOption ${inputMode === "location" ? "selected" : ""}`}
        onClick={() => handleModeChange("location")}
      >
        Helyszín
      </div>
      <div
        style={{ background: inputMode === "qr" ? primaryColor : "white" }}
        className={`switchOption ${inputMode === "qr" ? "selected" : ""}`}
        onClick={() => handleModeChange("qr")}
      >
        QR-kód
      </div>
    </div>
  </div>
)}
        {cardList()}
        <hr className='hrc'/>
        <p className='inputLabel'>Ár (Ft)</p>
            <p style={{marginBottom: "15px", marginTop: "5px", fontWeight: "bolder", color: primaryColor}}>{formatPrice(getAllPrice())}</p>
            <p className='inputLabel'>Fizetési mód</p>
              <select className='mobileInput' value={type} onChange={e => setType(e.target.value)} required>
                  <option disabled></option>
            <option value="Bankkártyás">Bankkártyás</option>
            <option value="Készpénzes">Készpénzes</option>
            </select>

            <hr className='hrc'/>
            {!isLoading && (<button type='submit' className='mobileInput submitbutton' style={{backgroundColor: primaryColor, marginBottom: "50px"}}>Adat felvitele</button>)}
          </form>}
    </div>
  );
}