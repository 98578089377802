import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import jwt_decode from "jwt-decode";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import {
  getEveryPromoter,
} from "../../Services/Api/PromoterApi";

import {
  getTimeSheet,
  postTimeSheet,
  postFreeDays
} from "../../Services/Api/TimeheetApi";

let adatfeltoltes = 0; // ez azert kell mert a owkrdays allandoan lefutott
export default function Timeheet() {
  const [allPromoters, setallPromoters] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getEveryPromoter().then((data) => {
      setallPromoters(
        data.filter(
          (obj) =>
            obj.username !== "László Emma" &&
            obj.username !== "Zentai Zsófi" &&
            obj.isActive === 1
        )
      );
    });
  };
  return (
    <div id="idobeosztas">
      <a href="/weeklytimesheet" className="mastersButton">
        Heti beosztás összefoglaló
      </a>
      <h2>Beosztás</h2>
      {allPromoters.length < 1 && <div className="loader"></div>}
      {allPromoters.length > 0 && <Calendar allPromoters={allPromoters} />}{" "}
      {/* Helyes JSX szintaxis */}
    </div>
  );
}

const Calendar = ({ allPromoters }) => {
  const [selectedPromoter, setSelectedPromoter] = useState(
    parseInt(allPromoters[0].id)
  );
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedPromoters, setSelectedPromoters] = useState([]);
  const [selectedFreePromoters, setSelectedFreePromoters] = useState([]);
  const [workDays, setWorkDays] = useState([]);
  const [allMonth, setAllMonth] = useState([]);
  const [isAllowedtoSet, setIsAllowedtoSet] = useState(false);
  const [isPosted, setIsPosted] = useState(false);
  const [selectedWorkingDays, setSelectedWorkingDays] = useState([]);
  const [freeDaysForUser, setfreeDaysForUser] = useState([]);
  const holidays = [
    "2023-08-20",
    "2023-10-23",
    "2023-11-01",
    "2023-12-24",
    "2023-12-25",
    "2023-12-26",
    "2023-12-31",
    "2024-01-01",
    "2024-03-15",
    "2024-03-29",
    "2024-03-31",
    "2024-04-01",
    "2024-05-01",
    "2024-05-20",
    "2024-05-19",
    "2024-08-20",
    "2024-10-23",
    "2024-11-01",
    "2024-12-24",
    "2024-12-25",
    "2024-12-26",
    "2024-12-31",
    "2025-01-01",
    "2025-03-15",
    "2025-04-18",
    "2025-04-20",
    "2025-04-21",
    "2025-05-01",
    "2025-06-08",
    "2025-06-09",
    "2025-08-20",
    "2025-10-23",
    "2025-11-01",
    "2025-12-25",
    "2025-12-26"
  ];
  const handlePrevMonth = () => {
    setSelectedDays([]);
    setSelectedWorkingDays([]);
    adatfeltoltes = 0;
    const prevMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1
    );
    setCurrentMonth(prevMonth);
  };

  const handleNextMonth = () => {
    setSelectedWorkingDays([]);
    setSelectedDays([]);
    adatfeltoltes = 0;
    const nextMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1
    );
    setCurrentMonth(nextMonth);
  };
  useEffect(() => {
    let decoded = jwt_decode(localStorage.getItem("accessToken"));
      if(decoded.priv === 2){
        setSelectedPromoter(decoded.id);
      }
    fetchDateData();
  }, [currentMonth, isPosted, selectedPromoter, selectedWorkingDays]);

  const fetchDateData = () => {
    if (localStorage.getItem("isUserLoggedIn")) {
      let decoded = jwt_decode(localStorage.getItem("accessToken"));
      if(decoded.priv === 2){
        setSelectedPromoter(decoded.id);
      }
      const yearMonth =  currentMonth.getFullYear().toString() + "-" + (currentMonth.getMonth() + 1).toString().padStart(2, "0")
      
      getTimeSheet(yearMonth).then((data) => {
        setAllMonth(data);
        const userWorkDays = [];
        const userFreeDays = [];
        data.forEach((day) => {
          const workingPromoters = JSON.parse(day["workingpromoters"]);
          if (workingPromoters.includes(selectedPromoter)) {
            userWorkDays.push(parseInt(day["date"].split("-")[2]));
          }

          const freePromoters = JSON.parse(day["freepromoters"]);
          if (freePromoters.includes(selectedPromoter)) {
            userFreeDays.push(parseInt(day["date"].split("-")[2]));
          }
        });

        // Update state with filteredWorkDays
        setWorkDays(userWorkDays);
        setSelectedDays(userFreeDays);
        if (userFreeDays.length > 4) {
          setIsAllowedtoSet(false);
        } else {
          setIsAllowedtoSet(true);
        }
        setfreeDaysForUser(userFreeDays);
      });
     
    } else {
      window.location = "/login";
    }
  };
  const handleInfoClick = (day) => {
    const monthinString =
      currentMonth.getFullYear().toString() +
      "-" +
      (currentMonth.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");
    if (allMonth.filter((obj) => obj.date === monthinString).length > 0) {
      setSelectedPromoters(
        JSON.parse(
          allMonth.filter((obj) => obj.date === monthinString)[0][
            "workingpromoters"
          ]
        ).map((num) => num.toString())
      );
      setSelectedFreePromoters(
        JSON.parse(
          allMonth.filter((obj) => obj.date === monthinString)[0][
            "freepromoters"
          ]
        ).map((num) => num.toString())
      );
    } else {
      setSelectedPromoters([]);
      setSelectedFreePromoters([]);
    }
  };
  const handleDayClick = (day) => {
    var decoded = jwt_decode(localStorage.getItem("accessToken"));
    const selectedDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day
    );
    const monthinString =
      currentMonth.getFullYear().toString() +
      "-" +
      (currentMonth.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");

    const selectedWeek = selectedDate.getDay();
    const selectedDaysInWeek = selectedDays.filter((selectedDay) => {
      const selectedDayDate = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        selectedDay
      );
      return selectedDayDate.getDay() === selectedWeek;
    });
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth() + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    const isHoliday = holidays.includes(formattedDate);
    if (decoded.priv === 2) {
      if (!isHoliday) {
        if (isAllowedtoSet && Date.parse(selectedDate) > Date.parse(Date())) {
          var userinLeave = 0;
          const filteredArray = allMonth.filter(
            (obj) => obj.date === monthinString
          );
          var userIsWork = false;
          if (filteredArray.length > 0) {
            userinLeave = JSON.parse(filteredArray[0]["freepromoters"]).length;
            if (
              JSON.parse(filteredArray[0]["workingpromoters"]).includes(
                decoded.id
              )
            ) {
              userIsWork = true;
            }
          }

          if (userinLeave / allPromoters.length < 0.4 && !userIsWork) {
            if (selectedDays.includes(day)) {
              setSelectedDays(
                selectedDays.filter((selectedDay) => selectedDay !== day)
              );
            } else {
              if (selectedDaysInWeek.length < 2 && selectedDays.length < 5) {
                setSelectedDays([...selectedDays, day]);
              }
            }
          } else {
            alert("Erre a napra nem tudsz már szabadnapot kérni");
          }
        } else {
          alert("Erre a napra nem tudsz már szabadnapot kérni");
        }
      } else {
        alert("Ez a nap nem munkanap");
      }
    }
  };
  const renderUsers = (user) => {
    if (user.length > 0) {
      return <div>{user[0].username}</div>;
    } else {
      return <div>{user}</div>;
    }
  };

  const renderPopup = (day) => {
    const year = currentMonth.getFullYear();
    const monthName = currentMonth.toLocaleString("hu", { month: "long" });
    const monthinString =
      currentMonth.getFullYear().toString() +
      "-" +
      (currentMonth.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");
    var decoded = jwt_decode(localStorage.getItem("accessToken"));
    const month = currentMonth.getMonth() + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    const isHoliday = holidays.includes(formattedDate);
    // Render the calendar
    if (isHoliday) {
      return (
        <div>
          <h3>Munkaszüneti nap</h3>
        </div>
      );
    }
    if (decoded.priv === 3) {
      return (
        <div style={{ pointerEvents: "none" }}>
          <div>
            <h2>
              {" "}
              {year} {monthName} {day}
            </h2>
            <hr></hr>
          </div>
          <div>
            <h3>Ezen a napon dolgoznak</h3>
            {allMonth
              .filter((obj) => obj.date === monthinString)
              .map((day) => (
                <div>
                  {JSON.parse(day["workingpromoters"]).map((promoters) => (
                    <div className="users">
                      {renderUsers(
                        allPromoters.filter(
                          (obj) => obj.id === promoters
                        )
                      )}
                    </div>
                  ))}
                </div>
              ))}
            <hr></hr>
            <h3>Ezen a napon szabadnaposok</h3>
            {allMonth
              .filter((obj) => obj.date === monthinString)
              .map((day) => (
                <div>
                  {day["freepromoters"].map((promoters) => (
                    <div className="users">
                      {renderUsers(
                        allPromoters.filter(
                          (obj) => obj.id === promoters
                        )
                      )}
                    </div>
                  ))}
                </div>
              ))}
            <hr></hr>
            {/*   <h3>Válaszd ki kik dolgozzanak ezen a napon</h3>

            {allPromoters.map((promoter) => (
              <div className='users selectUser' style={selectedPromoters.includes(promoter.id) ? { background: "black", color: "white" } : {}} onClick={() => handleSelectUser(day, promoter)}>{promoter.username}</div>
            ))}
            <button className='confirmbutton' onClick={() => handlePromoterConfirm(day)}>Megerősítés</button>
            <hr></hr>
            <h3>Válaszd ki kik legyenek szabadságon ezen a napon</h3>

            {allPromoters.map((promoter) => (
              <div className='users selectUser' style={selectedFreePromoters.includes(promoter.id) ? { background: "black", color: "white" } : {}} onClick={() => handleFreeSelectUser(day, promoter)}>{promoter.username}</div>
            ))}
            <button className='confirmbutton' onClick={() => handleFreePromoterConfirm(day)}>Megerősítés</button> */}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <h2>
              {" "}
              {year} {monthName} {day}
            </h2>
            <hr></hr>
          </div>
          <div>
            <h3>Ezen a napon dolgoznak</h3>
            {allMonth
              .filter((obj) => obj.date === monthinString)
              .map((day) => (
                <div>
                  {JSON.parse(day["workingpromoters"]).map((promoters) => (
                    <div className="users">
                      {renderUsers(
                        allPromoters.filter(
                          (obj) => obj.id === promoters
                        )
                      )}
                    </div>
                  ))}
                </div>
              ))}
            <hr></hr>
            <h3>Ezen a napon szabadnaposok</h3>
            {allMonth
              .filter((obj) => obj.date === monthinString)
              .map((day) => (
                <div>
                  {JSON.parse(day["freepromoters"]).map((promoters) => (
                    <div className="users">
                      {renderUsers(
                        allPromoters.filter(
                          (obj) => obj.id === promoters
                        )
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      );
    }
  };

  const renderCalendar = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const monthName = currentMonth.toLocaleString("hu", { month: "long" });
    const weekdays = ["Hét", "Kedd", "Szer", "Csüt", "Pén", "Szom", "Vas"];
    // Get the first day of the current month
    const firstDay = new Date(year, month, 1);

    // Get the number of days in the current month
    const totalDays = new Date(year, month + 1, 0).getDate();

    // Get the weekday index of the first day (0 - Sunday, 1 - Monday, ...)
    var startDayIndex = firstDay.getDay() - 1;
    if (startDayIndex === -1) {
      startDayIndex = 6;
    }
    // Create an array to hold the calendar days
    const calendarDays = [];

    // Fill in the days before the start of the current month
    for (let i = 0; i < startDayIndex; i++) {
      calendarDays.push(
        <div key={`prev-${i}`} className="calendar-day empty"></div>
      );
    }

    // Fill in the days of the current month
    let selectedWorkingDaysByAdmin = selectedWorkingDays.map((day) =>
      parseInt(day.split("-")[2])
    );
    for (let i = 1; i <= totalDays; i++) {
      const isSelected =
        selectedDays.includes(i) || selectedWorkingDaysByAdmin.includes(i);
      const isWorkday =
        selectedWorkingDaysByAdmin.length > 0
          ? selectedWorkingDaysByAdmin.includes(i)
          : workDays.includes(i);
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth() + 1;
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${i
        .toString()
        .padStart(2, "0")}`;
      const isHoliday = holidays.includes(formattedDate);
      calendarDays.push(
        <div
          selected={isSelected}
          key={`current-${i}`}
          className={`calendar-day ${
            isSelected
              ? "selected"
              : isHoliday
              ? "selected"
              : isWorkday
              ? "work-day"
              : "empty"
          } ${selectedWorkingDaysByAdmin.includes(i) ? "work-day" : ""}`}
          onClick={(e) => setworkingDayforPromoter(i, selectedPromoter, e)}
        >
          <p className="day" onClick={() => handleDayClick(i)}>
            {i}
          </p>

          <div className="infobuttonholder">
            <Popup
              trigger={
                <button
                  className="infobutton"
                  onClick={() => handleInfoClick(i)}
                >
                  <FontAwesomeIcon className="faiconinfo" icon={faInfoCircle} />
                </button>
              }
              modal
              onOpen={() => handleInfoClick(i)}
            >
              <div>{renderPopup(i)}</div>
            </Popup>
          </div>
        </div>
      );
    }
    // Render the calendar
    return (
      <div>
        <div className="calendar-header">
          <button
            className="nextbutton"
            style={{ color: "black" }}
            onClick={handlePrevMonth}
          >
            Előző hónap
          </button>
          <h2>
            {monthName} {year}
          </h2>
          <button
            className="nextbutton"
            style={{ color: "black" }}
            onClick={handleNextMonth}
          >
            Következő hónap
          </button>
        </div>
        <div className="calendar-days-header">
          {weekdays.map((day) => (
            <div key={day} className="calendar-day-header">
              {day}
            </div>
          ))}
        </div>
        <div className="calendar">{calendarDays}</div>
        {selectedWorkingDaysByAdmin.length > 0 ? (
          <>
            <button
              style={{ width: "150px", border: "2px solid" }}
              className={"work-day"}
              onClick={() => savingWorkdDaysForPromoter()}
            >
              Mentés
            </button>
          </>
        ) : (
          <div></div>
        )}
      </div>
    );
  };
  const savingWorkdDaysForPromoter = async () => {
    const nameOfPromotes = allPromoters.find((name) => {
      if (parseInt(name.id) === selectedPromoter) {
        return name;
      }
    });

    const name = nameOfPromotes.username;
    let answer = window.confirm(
      "Biztosan menteni szeretnéd " + nameOfPromotes.username + " munkanapjait?"
    );
    if (answer) {
      if (localStorage.getItem("isUserLoggedIn") === "true") {
        let params = {
          selectedPromoter: selectedPromoter,
          selectedWorkingDays: selectedWorkingDays,
        };
        postTimeSheet(params).then((response) => {
          window.alert("Sikeres mentés");
        }).catch((error) => {
          window.alert("Hiba történt!" + error);
        });
      }
    }
  };

  const handleConfirmation = () => {
    if (selectedDays.length < 5) {
      alert("Válaszd ki mind az 5 napot a hónapra");
    } else {
      if (
        window.confirm(
          "Biztosan szeretnéd menteni a kiválasztott szabadnapokat? Később ezeket nem tudod módosítani!"
        )
      ) {
        const yearMonth =  currentMonth.getFullYear().toString() + "-" + (currentMonth.getMonth() + 1).toString().padStart(2, "0")
        var selectedDaysArray = [];
        var decoded = jwt_decode(localStorage.getItem("accessToken"));
        selectedDays.forEach((day) => {
          const timeof = yearMonth + "-" + day.toString().padStart(2, "0");
          selectedDaysArray.push(timeof);
        });
        var json = {
            userId: decoded.id,
            date: selectedDaysArray,
            setFreeDays: "true",
            initFree : "false",
            initWork : "false",
            setWorkDays: "false"
        }
        
        postFreeDays(json).then((response) => {
          setIsAllowedtoSet(false);
          setIsPosted(true);
        }).catch((error) => {
          alert("Hiba történt!" + error);
        });
      }
    }
  };
  var decoded = jwt_decode(localStorage.getItem("accessToken"));

  function selectingPromoter(event) {
    if (event) {
      const newSelectedPromoter = parseInt(event);
      setSelectedPromoter(newSelectedPromoter);
      setSelectedWorkingDays([]);
  
      // Frissítjük a munkanapokat az új promoterhez
      const userWorkDays = [];
      const userFreeDays = [];
      const userSelectedWorkingDays = [];
      
      allMonth.forEach((day) => {
        const workingPromoters = JSON.parse(day["workingpromoters"]);
        if (workingPromoters.includes(newSelectedPromoter)) {
          userWorkDays.push(parseInt(day["date"].split("-")[2]));
          userSelectedWorkingDays.push(day["date"]);
        }

        const freePromoters = JSON.parse(day["freepromoters"]);
        if (freePromoters.includes(newSelectedPromoter)) {
          userFreeDays.push(parseInt(day["date"].split("-")[2]));
        }
      });
  
      setWorkDays(userWorkDays);
      setSelectedDays(userFreeDays);
      setSelectedWorkingDays(userSelectedWorkingDays);
    }
  }

  function setworkingDayforPromoter(day, promoter, e) {
    const decoded = jwt_decode(localStorage.getItem("accessToken"));
    if(decoded.priv === 1 || decoded.priv === 0){
    let selectedWorkingDaysArray = selectedWorkingDays;
    const time =
      currentMonth.getFullYear().toString() +
      "-" +
      (currentMonth.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");
    if (!(freeDaysForUser.includes(day) || holidays.includes(time))) {
      let workDaysDateUj = [];
      if (adatfeltoltes === 0) {
        workDaysDateUj = workDays.map(
          (nap) =>
            currentMonth.getFullYear().toString() +
            "-" +
            (currentMonth.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            nap.toString().padStart(2, "0")
        );
        setWorkDays([]);
        adatfeltoltes++;
        // azert kell ez mert a fetchDataból allandoan megfut es frissti a workDays-t
      }

      let mergeworkingDays = [...selectedWorkingDaysArray, ...workDaysDateUj];
      let mergeworkingDays2 = [...new Set(mergeworkingDays)];

      if (mergeworkingDays2.find((t) => t === time)) {
        mergeworkingDays2.filter(function (t) {
          return t !== time;
        });
        setSelectedWorkingDays(
          mergeworkingDays2.filter(function (t) {
            return t !== time;
          })
        );
      } else {
        const updatedWorkingDays = [...mergeworkingDays2, time];
        setSelectedWorkingDays(updatedWorkingDays);
        
      }
    }
  }
  }
  return (
    <div>
      <div className="selected-days">
        {selectedDays.length > 0 ? (
          <>
            <p className="select-days-text">
              Kiválasztott szabadnapjaid erre a hónapra:
            </p>
            {selectedDays
              .sort((a, b) => a - b)
              .map((day) => (
                <span key={day} className="selected-day">
                  {day}
                </span>
              ))}
            {isAllowedtoSet === true ? (
              <button
                onClick={handleConfirmation}
                className="confirmation-button"
              >
                Megerősítés
              </button>
            ) : (
              <p></p>
            )}
          </>
        ) : (
          <div>
            {(decoded.priv !== 1 && decoded.priv !== 0) && (
              <>
                <p className="select-days-text">Válaszd ki a szabadnapjaid</p>
                <p className="description">
                  Nyomj rá az adott napokra a kijelöléshez, majd ha kijelölted
                  az 5 napot, nyomj a megerősítés gombra
                </p>
              </>
            )}
          </div>
        )}
      </div>

      {(decoded.priv === 1 || decoded.priv == 0) && (
        <select
          defaultValue={
            selectedPromoter === 0
              ? parseInt(allPromoters[0].id)
              : selectedPromoter
          }
          value={selectedPromoter}
          onChange={(e) => selectingPromoter(e.target.value)}
        >
          {allPromoters.map((promoter) => (
            <option key={promoter.id} value={promoter.id}>
              {promoter.username}
            </option>
          ))}
        </select>
      )}
      {renderCalendar()}
      <div className="jelmagyarazat">
        <div
          style={{
            background: "#d63031",
            height: "25px",
            width: "25px",
            borderRadius: "5px",
            marginRight: "10px",
          }}
        ></div>{" "}
        Szabadnapok
      </div>
      <div className="jelmagyarazat">
        <div
          style={{
            background: "#00b894",
            height: "25px",
            width: "25px",
            borderRadius: "5px",
            marginRight: "10px",
          }}
        ></div>{" "}
        Munkanapok
      </div>
    </div>
  );
};
