import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);


// API lekérdezés a financehoz
export async function getFinance(queryParam) {
  try {
    const response = await apiManager.get(`/finance/?${queryParam}`);
    if (response.status === 200) {

        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

export async function postDaily(data) {
    try {
      const response = await apiManager.post('/finance/', data);
      if (response.status === 201) {
  
          return response.data;
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
}

export async function deleteDaily(expenseId) {
  try {
    const response = await apiManager.delete('/finance/?id=' + expenseId);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}

export async function updateFixedExpense(expenseId, newAmount) {
  try {
    const response = await apiManager.patch('/finance/', { id: expenseId, amount: newAmount });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}