import React, { useState, useContext } from 'react';
import './Navbar.css';
import {
  Nav,
  NavLink
} from './NavbarElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faRightFromBracket, faDatabase, faRemove, faClock, faWrench, faPerson, faCrown, faMoneyBill, faMoneyBillTransfer, faBarsStaggered, faIdCardAlt, faUserGraduate, faPersonShelter, faBroom, faChartBar } from '@fortawesome/free-solid-svg-icons';
import jwt_decode from "jwt-decode";
import { ThemeContext } from '../App/ThemeContext';

const Navbar = () => {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;

  const handleLogout = () => {
    localStorage.setItem("accessToken", "");
    localStorage.setItem("isUserLoggedIn", false);
    window.location = '/login'
  }

  const handleClick = () => {
    setIsToggleOn(!isToggleOn);
  }
  
  const handleClose = () => {
    setIsToggleOn(false);
  }

  if (localStorage.getItem("isUserLoggedIn") === "true") {
    var decodedUser = {};
    try {
      decodedUser = jwt_decode(localStorage.getItem("accessToken"));
      console.log(localStorage.getItem("accessToken"))
    } catch (error) {
      localStorage.clear();
      window.location = '/login';
      console.error("Invalid token specified", error);
      localStorage.setItem('isUserLoggedIn', false);
    }
    
    return (
      <>
        <button className='navbar' onClick={handleClick}><FontAwesomeIcon className='faicon' icon={faBars} /></button>
        {isToggleOn && (
          <div className='background' onClick={handleClose}>
            <div className='fullscreen'>
            <div className='headerImage' style={{position: "fixed", width: "200px;", backgroundColor: primaryColor}}>
                <img alt="Slim Store Logo" style={{ width: "80px", marginTop: "10px" }} src={'https://leltar.slimstore.hu/slimlogo.png'} />
              </div>
              <Nav className='navMenu'>
                <NavLink style={{ marginTop: "100px" }} className='menuItem' to='/statisztika' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faChartBar} />Statisztika
                </NavLink>
                <NavLink  className='menuItem' to='/leltar' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faDatabase} />Leltár
                </NavLink>
                {( decodedUser.jobrole !== "inventory" && decodedUser.priv === 1 || decodedUser.priv === 0) && (
                  <NavLink className='menuItem' to='/penzugyi-jelentes' activeStyle onClick={handleClick}>
                    <FontAwesomeIcon className='famenu menuicon' icon={faMoneyBillTransfer} />Pénzügyi összesítő
                  </NavLink>
                )}
                {(decodedUser.jobrole !== "inventory") && (
                <NavLink className='menuItem' to='/new' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faPlus} />Új eladás
                </NavLink>)}
                {(decodedUser.jobrole !== "inventory") && (
                <NavLink className='menuItem' to='/tisztitas' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faBroom} />Tisztítás kártya érvényesítés
                </NavLink>)}
                {(decodedUser.jobrole !== "inventory") && (
                <NavLink className='menuItem' to='/replace' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faRemove} />Új csere/selejt/próba
                </NavLink>)}
                {(decodedUser.jobrole !== "inventory") && (
                <NavLink className='menuItem' to='/card' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faIdCardAlt} />Kártya beváltás
                </NavLink>)}

                {( decodedUser.jobrole !== "inventory" && (decodedUser.jobrole !== "intern"  ||  decodedUser.priv === 1 || decodedUser.priv === 0)) && (
                  <NavLink className='menuItem' to='/masters' activeStyle onClick={handleClick}>
                      <FontAwesomeIcon className='famenu menuicon' icon={faCrown} />Masters
                  </NavLink>)}

                {(decodedUser.jobrole !== "inventory" && (decodedUser.jobrole === "intern" || decodedUser.priv === 1 || decodedUser.priv === 0)) && (
                  <NavLink className='menuItem' to='/interns' activeStyle onClick={handleClick}>
                    <FontAwesomeIcon className='famenu menuicon' icon={faUserGraduate} />Betanulók
                  </NavLink>
                )}
                {(decodedUser.jobrole !== "inventory" && (decodedUser.jobrole === "intern" || decodedUser.priv === 1 || decodedUser.priv === 0)) && (
                  <NavLink className='menuItem' to='/addSlimmerIntern' activeStyle onClick={handleClick}>
                    <FontAwesomeIcon className='famenu menuicon' icon={faUserGraduate} />Slimmer regisztráció felvitele
                  </NavLink>
                )}
                {(decodedUser.jobrole !== "inventory" && (decodedUser.priv === 1 || decodedUser.priv === 0) ) && (
                  <NavLink className='menuItem' to='/slimmerInternList' activeStyle onClick={handleClick}>
                    <FontAwesomeIcon className='famenu menuicon' icon={faUserGraduate} />Slimmer regisztráció lista
                  </NavLink>
                )}
                {(decodedUser.jobrole !== "inventory") && (
                <NavLink  className='menuItem' to='/idobeosztas' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faClock} />Időbeosztás
                </NavLink>
                )}
                {(decodedUser.jobrole !== "inventory" && (decodedUser.priv === 1 ||  decodedUser.priv === 0) ) && (
                  <NavLink className='menuItem' to='/settings' activeStyle onClick={handleClick}>
                    <FontAwesomeIcon className='famenu menuicon' icon={faWrench} />Beállítások
                  </NavLink>
                )}
                {( decodedUser.priv === 1 ||   decodedUser.priv === 0) && (
                  <NavLink className='menuItem' to='/raktarkeszlet' activeStyle onClick={handleClick}>
                    <FontAwesomeIcon className='famenu menuicon' icon={faMoneyBillTransfer} />Raktárkészlet
                  </NavLink>
                )}
                {( decodedUser.priv === 0 || decodedUser.jobrole !== "inventory") && (
                  <NavLink className='menuItem' to='/franchise-raktarkeszlet' activeStyle onClick={handleClick}>
                    <FontAwesomeIcon className='famenu menuicon' icon={faMoneyBillTransfer} />Franchise raktárkészlet
                  </NavLink>
                )}
                <NavLink className='menuItem' to='/profile' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faPerson} />Profil
                </NavLink>
                <NavLink style={{ marginBottom: "200px" }}  className='menuItem logout' to='/logout' activeStyle onClick={handleLogout}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faRightFromBracket} />Kijelentkezés
                </NavLink>
              </Nav>
            </div>
          </div>
        )}
        {!isToggleOn && (<div className='mobile-navbar'>
          <Nav className='navMenu1 '>
            <NavLink className='menuItem1 bottomnavbarmenuitem' to='/leltar' activeStyle>
              <FontAwesomeIcon className='famenu faiconmenu' icon={faDatabase} /><br></br>Leltár
            </NavLink>
            <NavLink className='menuItem1 bottomnavbarmenuitem' to='/new' activeStyle>
              <FontAwesomeIcon className='famenu faiconmenu' icon={faPlus} /><br></br>Eladás
            </NavLink>
            <NavLink className='menuItem1 bottomnavbarmenuitem' to='/statisztika' activeStyle>
              <FontAwesomeIcon className='famenu faiconmenu' icon={faChartBar} /><br></br>Statisztika
            </NavLink>
            <NavLink className='menuItem1 bottomnavbarmenuitem' to='/replace' activeStyle>
              <FontAwesomeIcon className='famenu faiconmenu' icon={faRemove} /><br></br>Csere/selejt
            </NavLink>
            <p className='menuItem1 bottomnavbarmenuitem' onClick={handleClick}><FontAwesomeIcon className='famenu faiconmenu' icon={faBarsStaggered} /><br></br>Több</p>
          </Nav>
        </div>)}
      </>)} else {
    return (
      <div>
       
      </div>
    )}}

    export default Navbar;